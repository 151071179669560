<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="serviceData-container">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="common-screen-container">
      <div v-if="!$vuex.state.onlyCompany" class="common-input-container">
        <span>所属公司:</span>
        <el-select
          class="common-screen-input"
          v-model="params.companyId"
          @change="companyFun"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in rolesCompany"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>所属部门:</span>
        <el-cascader
          class="common-screen-input"
          v-model="params.departId"
          :options="selectDepart"
          @change="commonFun"
          :props="{
            label: 'name',
            children: 'child',
            value: 'id',
            emitPath: false,
          }"
        ></el-cascader>
      </div>
      <br />
      <div class="common-input-container">
        <el-date-picker
          v-model="params.datePicker"
          :clearable="false"
          @change="
            () => {
              changeDate();
            }
          "
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <div class="common-input-container">
        <el-radio-group v-model="params.type" @change="changeRadio">
          <el-radio-button :label="1">近7天</el-radio-button>
          <el-radio-button :label="2">近30天</el-radio-button>
          <el-radio-button :label="3">近90天</el-radio-button>
          <el-radio-button :label="0">昨天</el-radio-button>
        </el-radio-group>
      </div>
      <el-button
        class="common-screen-btn"
        type="primary"
        @click="
          () => {
            getServeData();
          }
        "
        >查 询</el-button
      >
      <el-button class="common-screen-btn" plain @click="() => resetBtn()"
        >重 置</el-button
      >
    </div>
    <div class="common-upload-container" style="margin: 0">
      <el-button class="common-screen-btn" type="primary" :loading="btnLoading">上传数据表格</el-button>
      <input
       v-if="!btnLoading"
        class="common-upload-input"
        @input="
          (e) => {
            uploadDataXslx(e);
          }
        "
        type="file"
      />
    </div>
    <el-button class="common-screen-btn" @click="() => download()"
      >导出数据表格</el-button
    >
    <el-button class="common-screen-btn" type="text" @click="() => downloadTemplate()">模板下载</el-button>
    <div class="serviceData-content">
      <table class="serviceData-table">
        <tbody>
          <tr>
            <th>咨询人数</th>
            <th>接待人数</th>
            <th>首响时间(s)</th>
            <th>平响时间(s)</th>
            <th>问答比</th>
            <th>回复率</th>
          </tr>
          <tr>
            <td>{{ serveData.consultationNum || "--" }}</td>
            <td>{{ serveData.receptionNum || "--" }}</td>
            <td>{{ serveData.firstTime || "--" }}</td>
            <td>{{ serveData.avgTime || "--" }}</td>
            <td>{{ serveData.qaRate || "--" }}</td>
            <td>{{ serveData.replyRate || "--" }}</td>
          </tr>
        </tbody>
      </table>
      <div id="echartsRadar"></div>
    </div>
    <el-table class="common-table" :data="tableData" @sort-change="tableSort">
      <el-table-column prop="shopLogo" width="160px">
        <template slot-scope="scope">
           <span>
            <img
              class="common-iconImg"
              v-if="scope.row.shopLogo"
              :src="scope.row.shopLogo"
            />
            <span v-if="!scope.row.shopLogo"
              ><i class="el-icon-user common-iconImg-MR"></i
            ></span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="shopName"
        label="店铺名称"
        :formatter="tableColumn"
        width="180px"
      ></el-table-column>
      <el-table-column
        prop="consultationNum"
        label="咨询人数"
        sortable="custom"
        :formatter="tableColumn"
        width="180px"
      ></el-table-column>
      <el-table-column
        prop="receptionNum"
        label="接待人数"
        sortable="custom"
        :formatter="tableColumn"
         width="180px"
      ></el-table-column>
      <el-table-column
        prop="firstTime"
        label="首响时间（s）"
        sortable="custom"
        :formatter="tableColumn"
        width="180px"
      ></el-table-column>
      <el-table-column
        prop="avgTime"
        label="平响时间（s）"
        sortable="custom"
        :formatter="tableColumn"
        width="180px"
      ></el-table-column>
      <el-table-column
        prop="qaRate"
        label="问答比"
        sortable="custom"
        :formatter="tableColumn"
        width="180px"
      ></el-table-column>
      <el-table-column
        prop="replyRate"
        label="回复率"
        sortable="custom"
        :formatter="tableColumn"
        width="180px"
      ></el-table-column>
      <el-table-column label="操作" width="120px" fixed="right">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.shopId"
            type="text"
            size="small"
            @click="
              () => {
                $router.push({
                  path: '/data/customer-service-data/customer-service-detail',
                  query: {
                    shopId: scope.row.shopId,
                    shopName: scope.row.shopName,
                    startTime: DateTransform(params.datePicker[0]),
                    endTime: DateTransform(params.datePicker[1]),
                  },
                });
              }
            "
            >查看详情</el-button
          >
          <el-button v-else type="text" style="color: #999" size="small"
            >查看详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.page"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import {
  getLatelyDay,
  DateTransform,
  Config,
  tableColumn,
  toLine,
} from "../../utils/index.js";
import {
  getServeData,
  getServeDataList,
  uploadDataXslx,
} from "../../service/dataInfo.js";
import { commonDepartAll } from "../../service/common.js";
export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      DateTransform,
      tableColumn,
      btnLoading: false,
      loading: false,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "数据", isLink: false },
        { title: "店铺服务数据", isLink: false },
      ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      params: {
        type: 1, // 筛选类型 今天/30天/90天 ...
        datePicker: getLatelyDay(7), // 时间范围选择-默认7天
        page: 1,
        pageSize: 10,
      },
      serveData: {}, // 店铺服务数据
      selectDepart: [], // 部门下拉
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company, // 所属公司下拉
      tableData: [],
    };
  },
  mounted() {
    this.echartsRadar();
  },
  created() {
    if (this.rolesCompany) {
      this.params.companyId = this.rolesCompany[0].id;
      this.commonDepartAll(this.rolesCompany[0].id);
    }
    this.getServeData();
  },
  methods: {
    commonFun() {
      this.params.page = 1;
      this.params.pageSize = 10;
      this.getServeData();
    },
    downloadTemplate() {
      // 下载模板-暂时写死
      window.location.href =
        "https://sy-my.oss-cn-zhangjiakou.aliyuncs.com/sy/file/%E6%91%A9%E4%BA%BF%E6%9C%8D%E5%8A%A1%E6%95%B0%E6%8D%AE%E6%A8%A1%E6%9D%BF.xlsx";
    },
    async uploadDataXslx(e) {
      // 上传员工表格
      this.btnLoading = true;
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (format != "xls" && format != "xlsx") {
        this.$message.error("仅支持xls、xlsx格式文件");
        e.target.value = "";
        this.btnLoading = false;
        return;
      }
      await uploadDataXslx({ file: file });
      this.btnLoading = false;
      e.target.value = "";
      this.$message.success("上传成功");
      this.getServeData()
    },
    download() {
      let params = { ...this.params };
      params.startDate = DateTransform(params.datePicker[0]);
      params.endDate = DateTransform(params.datePicker[1]);
      delete params.datePicker;
      delete params.page;
      delete params.pageSize;
      if (!params.type) {
        delete params.type;
      }
      let departIdType = typeof params.departId;
      if (departIdType == "object" || departIdType == "undefined") {
        delete params.departId;
      }
      let url = Config.api.url + "/dataRecord/serveData/excel/detailExport";
      let paramArr = [];
      for (let item in params) {
        if (params[item]) {
          paramArr.push(item + "=" + params[item]);
        }
      }
      url = url + "?" + paramArr.join("&");
      window.location.href = url;
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.page = e;
      }
      this.getServeData();
    },
    async getServeDataList() {
      // 获取表格数据
      let params = { ...this.params };
      params.startDate = DateTransform(params.datePicker[0]);
      params.endDate = DateTransform(params.datePicker[1]);
      delete params.datePicker;
      if (!params.type) {
        delete params.type;
      }
      let departIdType = typeof params.departId;
      if (departIdType == "object" || departIdType == "undefined") {
        delete params.departId;
      }
      if (!params.sortType) {
        delete params.sortField;
        delete params.sortType;
      }
      let resData = (await getServeDataList(params)).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.list;
    },
    resetBtn() {
      this.params = {
        type: 0, // 筛选类型 今天/30天/90天 ...
        datePicker: [new Date(), new Date()], // 时间范围选择-默认当天
        companyId: this.rolesCompany[0].id,
        page: 1,
        pageSize: 10,
      };
      this.commonDepartAll(this.rolesCompany[0].id);
      this.getServeData();
    },
    companyFun(id) {
      //选择公司筛选
      this.params.departId = [];
      this.getServeData();
      this.commonDepartAll(id);
    },
    async commonDepartAll(id) {
      // 部门 下拉
      let resData = (await commonDepartAll({}, id)).data;
      this.selectDepart = resData ? resData : [];
    },
    changeDate() {
      // 选择时间时清空近几天类型选择
      this.params.type = null;
      this.getServeData();
    },
    async getServeData() {
      // 索取服务数据
      let params = { ...this.params };
      this.loading = true;
      params.startDate = DateTransform(params.datePicker[0]);
      params.endDate = DateTransform(params.datePicker[1]);
      delete params.datePicker;
      if (!params.type) {
        delete params.type;
      }
      let departIdType = typeof params.departId;
      if (departIdType == "object" || departIdType == "undefined") {
        delete params.departId;
      }
      if (!params.sortType) {
        delete params.sortField;
        delete params.sortType;
      }
      delete params.page;
      delete params.pageSize;
      let resData = (await getServeData(params)).data;
      this.getServeDataList();
      this.serveData = resData;
      this.loading = false;
    },
    tableSort(e) {
      let params = { ...this.params };
      params.sortField = toLine(e.prop);
      switch (e.order) {
        case "ascending":
          params.sortType = 1;
          break;
        case "descending":
          params.sortType = 2;
          break;
        default:
          params.sortType = null;
      }
      this.params = params;
      this.getServeDataList();
    },
    echartsRadar() {
      // 雷达图
      let myChart = this.$echarts.init(document.getElementById("echartsRadar"));

      const buildSeries = function (data) {
        // 设置雷达图样式
        const helper = data.map((item, index) => {
          const arr = new Array(data.length);
          arr.splice(index, 1, item);
          return arr;
        });
        return [data, ...helper].map((item, index) => {
          return {
            type: "radar",
            symbol: index === 0 ? "circle" : "none",
            symbolSize: 6,
            itemStyle: {
              normal: {
                borderWidth: 1,
                borderColor: "#fff",
                color: "#1890ff",
              },
            },
            lineStyle: {
              color: index === 0 ? "#1890ff" : "transparent",
            },
            areaStyle: {
              color: index === 0 ? "#1890ff" : "transparent",
              opacity: 0.6,
            },
            label: {
              color: "#1890ff",
              normal: {
                show: true,
                formatter: (params) => {
                  return params.value ? params.value + "%" : "";
                },
                color: "#1890ff",
              },
            },
            tooltip: {
              show: index === 0 ? false : true,
              formatter: function () {
                return (
                  source.indicator[index - 1].name + "：" + source.data[index - 1] + "%"
                );
              },
            },
            z: index === 0 ? 1 : 2,
            data: [item],
          };
        });
      };
      const source = {
        data: [90, 80, 70, 60, 95, 70],
        indicator: [
          { name: "最大同时接待(%)", max: 100 },
          { name: "回复率(%)", max: 100 },
          { name: "平响时间(%)", max: 100 },
          { name: "主动性(%)", max: 100 },
          { name: "问答比(%)", max: 100 },
        ],
      };
      let option = {
        tooltip: {},
        radar: {
          shape: "circle",
          name: {
            textStyle: {
              color: "#000000A6",
              // backgroundColor: "#999",
              borderRadius: 3,
              padding: [3, 5],
            },
          },
          indicator: source.indicator,
        },
        series: buildSeries(source.data),
      };
      myChart.setOption(option);
    },
    changeRadio(e) {
      let date;
      switch (e) {
        case 0:
          date = 0;
          break;
        case 1:
          date = 7;
          break;
        case 2:
          date = 30;
          break;
        case 3:
          date = 90;
          break;
      }
      this.params.datePicker = getLatelyDay(date);
      this.getServeData();
    },
  },
};
</script>
<style lang="less" scoped>
.serviceData-container {
  text-align: left;
  .iconImg {
    width: 40px;
  }
  .serviceData-content {
    margin: 48px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    #echartsRadar {
      flex: 1;
      height: 300px;
      display: none;
    }
    .serviceData-table {
      display: table;
      width: 100%;
      border-collapse: collapse;
      td,
      th {
        text-align: center;
        border: 1px solid #c9c9c9;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
      }
      th {
        background: #f5f5f5;
        height: 60px;
        line-height: 60px;
        font-weight: 600;
      }
      td {
        height: 85px;
        line-height: 85px;
      }
    }
  }
}
</style>
